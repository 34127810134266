export const things = [
    {
      "name" : "warm haus",
      "number" : "one",
      "description" : "hello! remember your house warming party?? i showed up soaked, but that's okay because i got to hang out with you. sitting on your bed while you played records was 10/10 and i'm so glad that was the first but NOT the last time i got to do that this year. you've got insanely cool taste and i love when you send me muuusic!"
    },
    {
      "name" : "tiktok",
      "number" : "two",
      "description" : "you also sent me the first TikTok of many at your housewarming party. it was (i think) a clown covering One by Metallica on thee drums. not gonna lie i was nervous texting you back!! i made a stupid joke about the cops showing up and you didn't answer, but that's okay cause look at us now! watching TikToks with YOU is an activity that I Enjoy."
    },
    {
      "name" : "neighborshood",
      "number" : "three",
      "description" : "shortly after we warmed your apartment i moved into mine Just Across the Street. huge news. Biggest News yet even. Mr. Rogers would be proud of you because you are the Greatest Neighbor Of Them All. you're so kind and thoughtful and on time with rent. i am glad we are dating. that's super neat (and the best thing that has happened all year, it's in all the papers)"
    },
    {
      "name" : "exploring the heights",
      "number" : "four",
      "description" : "you offered to show me and my roommates around the neighborhood, but they were not around so their loss. i think this was the first time we really really hung out. talking to you is easily one of my favorite things to do because you got jokes & Facts about things that i find interesting! the map you made would go on to be the blueprint for many good times throughout the year, good times that We would Share..."
    },
    {
      "name" : "4/20 BLAZE IT",
      "number" : "five",
      "description" : "by this time we had hung out a few times and you were going on dates and i was going on dates but we were not going on dates with each other. that couldn't go on because, also by this time, i had a huge huge crush on you. i'm really glad i told you about it because life with you in it is amazing and BTW I LOVE YOU. even though we didn't smoke weed this was the best 4/20 BLAZE IT ever. kissing you at Bar Meridian got me high as fuck."
    },
    {
      "name" : "Official Pizza",
      "number" : "six",
      "description" : "a number of dates & hangs & shared yoga pants (HA HA HA) went by and things were going well. incredible! but we were still Just Dating and not BF/GFs. that all changed on my roof while we were eating pizza. you said i could call you my girlfriend and I Happily Obliged. thank you for being my girlfriend and partner!!"
    },
    {
      "name" : "Our First Vacation",
      "number" : "seven",
      "description" : "later that summer it was time to go on vacation. you were heading up to Maine Slash New Hampshire to visit your grandma and you invited me. which of course i was down for. this was our first vacation, and i think it was a pretty dang good one! we saw some frogs, walked around the town, and hung with your fam. i got way to high and freaked out by the three stooges but you made me feel better. at this point i'll go with you anywhere!! just invite me, i'll say yes!! (~SPOILERS~)"
    },
    {
      "name" : "OAK PARK",
      "number" : "eight",
      "description" : "before Our First Vacation was over the second one was already on the books. you invited me to Oak Park to hang out and meet your parents. Nice! that was a lot of fun. your parents are so kind and i feel honored to have met them. getting to see your old stomping grounds was sweeet. i enjoyed oak park quite a bit and hope to see it again someday soon. to many good times on that trip to fit here, but They're ALL IN MY HEAAAAADDDAGGAHGHA"
    },
    {
      "name" : "this ain't no picnic",
      "number" : "nine",
      "description" : "the catalyst for our trip to Oak Park was this here music festival. i, for one, had a great time. i would go on to cry tears of happiness on my roof thinking about how nice it felt standing next to you watching Courtney Barnett play. that was one of my favorite memories of the whole year. i feel so at home and like myself around you. every time we stop hanging out i get excited for the Next Time we hang out."
    },
    {
      "name" : "JAWS",
      "number" : "ten",
      "description" : "JAAAAWWWWSSSS. YESSSSSSSS. SHAAAARK. BIIIIITEEE. GRRRRRRRRRRRRRRRR. yessssssss. best $5 i ever spent. sharks are great and Jaws is a shark. i enjoy our joint love of sharks. i like your shark impression quite a lot. its pretty good! you can bite me anytime!!"
    },
    {
      "name" : "Break Bread Not Bad",
      "number" : "eleven",
      "description" : "BALD MEN WITH FRAGILE EGOSSS. we didn't finish Breaking Bad in 2022 but WE DID START IT. what a show. and what a person to get to watch it with. i like watching things with you, ya know? you're so observant and always have a good take. sometimes we watch something and talking about it wih you is better than whatever it is we watched! this happens quite often i think! let's watch more things in 2023 okay??"
    },
    {
      "name" : "tofutofutofutofu",
      "number" : "twelve",
      "description" : "we ate a lot of tofu this year. its true. but more important than all the tofu are your Values. you are Vegan to the Core, and i really admire your dedication and passion for the cause. its really inspiring and i love learning all the cool recipes you make! you love animals so much that it makes me love animals more, its great!! here's to many more vegan meals in the new year!"
    },
    {
      "name" : "seasonal beverages",
      "number" : "thirteen",
      "description" : "ah yes, our long standing tradition! for years now we have famously been die hard Starbucks Seasonal Beverage fans, and we sure lived up to that reputation this year!! we had 2 (count 'em -- TWO!!) Starbucks Seasonal Beverages this year. maybe we'll break our record in 2023! who knows? not me! stay tuned to find out......."
    },
    {
      "name" : "THE! LIST! OUR! LIST!",
      "number" : "fourteen",
      "description" : "2022 was also the year we started our Infaous List. i don't think we'll ever complete it, i don't really want to. i want it to keep on growing ad growing. there's always gonna be more shows to watch, foods to cook, pcps to smoke, and you're always gonna be the one i want to watch/cook/smoke with."
    },
    {
      "name" : "cold creeeek",
      "number" : "fifteen",
      "description" : "i think we're getting back on the linear track here. like, things going in order nd being based on events. like, the time we took the Metro North up to COLD CREEK and Hiked. this is something i would likely not do on my own, becausei lack conviction and drive. but! with you i feel like i can do anything, even hike!! we make a pretty good team when it comes to starting in one place and ending up in another, going to Cold Creek is an excellent example of us doing just that. we do tend to talk about getting cider more than we end up getting it though. we can work on that in 2023."
    },
    {
      "name" : "HallowShapes",
      "number" : "sixsixsixteen",
      "description" : "OMG WHat are we going to BE for Halloween? - Me. HMMM idk! - You. What about.. hmm, idk - Me. How about SHAPES - YOU. NICE - ME. That is how we decided to be shapes for Halloween. I loved being shapes with you, that was probably the coolest costume I have ever worn in my adult life. You make a super hot circle. I love your creativity and your QUIRKYNESS (sorry!). I've never met anyone like you, no one is even in the same Genre or Class. You are One Of A Kind, Limited Edition, and I'M SO GLAD YOU ARE MINE! <3"
    },
    {
      "name" : "astral plane hangs",
      "number" : "seventeen",
      "description" : "This on ei sn ot l in ear btu lrnaei nto draesm ronmylal rea. I have had so many dreams about you this year and they have all been good. We do so many fun things in my dreams, we go on adventures, we do nothing, we have sex, etc. I have had some of the hottest sex dreams of my life about you this year. I am getting turned on typing this right now thinking about you. I love youuuu"
    },
    {
      "name" : "newark forever",
      "number" : "eighteen",
      "description" : "Newark Literally Forever. We were stuck there for soooo long it was insane. We watched Breaking Bad, We Played MAD LIBS, WE ATE CHEX MIX. STILL NO FLIGHT. That was insane, and although it was not a good time, I'm glad I got to spend it with you. DOGGY STAYYY DOGGY NOT KICK OUT. DOGGY NO NEED RESPONSE."
    },
    {
      "name" : "TUCSON",
      "number" : "nineteen",
      "description" : "WOWEE. You x Tucson! Who woulda thought?? After enduring the Newark Tragedy of 2022 we eventually made it to The Dirty T. That is what the locals say the locals call it. I'm so glad you came with me to Tucson. Thanks for that 4 real. I'm glad you got to meet my family, and I'm glad they got to meet you. You're really the tops!!"
    },
    {
      "name" : "The Famous Sabino Canyon",
      "number" : "twentytwenty",
      "description" : "remember that place thats in that magazine that you have that you showed me?? it is real!! and you know this, because now, you have been! and We have been Together! Sabino Canyon! Even though we got us lost (and that's on me (and THAT's on GOD)) we got found too. Sabino Canyon is Beautiful but you are Even More Beautiful (AAAAAAAAWOOOOOOGAAAA 👀)"
    },
    {
      "name" : "the holidays!!!",
      "number" : "twentytwentyone",
      "description" : "We sure talked about The Holidays a lot this year. I'm glad they ended up being real. Sometimes, just because you talk about something, that doesn't mean it is always for real. That was luckily not the case this time. We are truly a RomCom Holiday Special Couple, and I think that is pretty neat. It was fun celebrating Hanukkah x Christmas with you. We should do it again sometime!!! What're you doing next week???"
    },
    {
      "name" : "I LOVE YOU",
      "number" : "twentytwentytwo",
      "description" : "The most important thing that happened this year is that I got to love you. You are so special to me, I'm so glad we found each other. You have the most incredible everthing about you. I just love the way you exist!! Thank you so much for spending so much time with me, I treasure every second we've shared, and so look forward to every second yet to come. 2022 was the best year of my life beause of you, and we only spent like 8 months of it together! Here's to a wonderful year, and many many more seconds spent and memories made! I Love You Claire!!!"
    },
    ]